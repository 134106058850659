import React from 'react';
import Header from './components/header';
import Footer from './components/Footer';
import { Outlet } from 'react-router-dom';
import useTokenAuth from '@common/utils/useTokenAuth';
// import Page2 from './page2/index.jsx'; // 官方 toolkit 写法
// import Page1 from './page1/index.jsx'; // 旧 redux

const Index = () => {
    useTokenAuth();

    return (
        <div
            style={{
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column'
            }}>
            <Header />

            <div
                style={{
                    width: '100%',
                    flex: 1
                }}>
                {/*  子路由占位  */}
                <Outlet />
            </div>

            <Footer />
        </div>
    );
};

export default React.memo(Index);
