import themeMap from '../../css/theme.js';
import { useSelector } from 'react-redux';

// const antdColorMap = {
//         blue: '#1677ff',
//         blue1: '#e6f4ff',
//         blue2: '#bae0ff',
//         blue3: '#91caff',
//         blue4: '#69b1ff',
//         blue5: '#4096ff',
//         blue6: '#1677ff',
//         blue7: '#0958d9',
//         blue8: '#003eb3',
//         blue9: '#002c8c',
//         blue10: '#001d66',
//         'blue-1': '#e6f4ff',
//         'blue-2': '#bae0ff',
//         'blue-3': '#91caff',
//         'blue-4': '#69b1ff',
//         'blue-5': '#4096ff',
//         'blue-6': '#1677ff',
//         'blue-7': '#0958d9',
//         'blue-8': '#003eb3',
//         'blue-9': '#002c8c',
//         'blue-10': '#001d66',
//         borderRadius: 6,
//         borderRadiusLG: 8,
//         borderRadiusOuter: 4,
//         borderRadiusSM: 4,
//         borderRadiusXS: 2,
//         boxShadow:
//             '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
//         boxShadowCard:
//             '\n      0 1px 2px -2px rgba(0, 0, 0, 0.16),\n      0 3px 6px 0 rgba(0, 0, 0, 0.12),\n      0 5px 12px 4px rgba(0, 0, 0, 0.09)\n    ',
//         boxShadowDrawerDown:
//             '\n      0 -6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 -3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 -9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
//         boxShadowDrawerLeft:
//             '\n      6px 0 16px 0 rgba(0, 0, 0, 0.08),\n      3px 0 6px -4px rgba(0, 0, 0, 0.12),\n      9px 0 28px 8px rgba(0, 0, 0, 0.05)\n    ',
//         boxShadowDrawerRight:
//             '\n      -6px 0 16px 0 rgba(0, 0, 0, 0.08),\n      -3px 0 6px -4px rgba(0, 0, 0, 0.12),\n      -9px 0 28px 8px rgba(0, 0, 0, 0.05)\n    ',
//         boxShadowDrawerUp:
//             '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
//         boxShadowPopoverArrow: '2px 2px 5px rgba(0, 0, 0, 0.05)',
//         boxShadowSecondary:
//             '\n      0 6px 16px 0 rgba(0, 0, 0, 0.08),\n      0 3px 6px -4px rgba(0, 0, 0, 0.12),\n      0 9px 28px 8px rgba(0, 0, 0, 0.05)\n    ',
//         boxShadowTabsOverflowBottom: 'inset 0 -10px 8px -8px rgba(0, 0, 0, 0.08)',
//         boxShadowTabsOverflowLeft: 'inset 10px 0 8px -8px rgba(0, 0, 0, 0.08)',
//         boxShadowTabsOverflowRight: 'inset -10px 0 8px -8px rgba(0, 0, 0, 0.08)',
//         boxShadowTabsOverflowTop: 'inset 0 10px 8px -8px rgba(0, 0, 0, 0.08)',
//         boxShadowTertiary:
//             '\n      0 1px 2px 0 rgba(0, 0, 0, 0.03),\n      0 1px 6px -1px rgba(0, 0, 0, 0.02),\n      0 2px 4px 0 rgba(0, 0, 0, 0.02)\n    ',
//         colorBgBase: '#fff',
//         colorBgBlur: 'transparent',
//         colorBgContainer: '#ffffff',
//         colorBgContainerDisabled: 'rgba(0, 0, 0, 0.04)',
//         colorBgElevated: '#ffffff',
//         colorBgLayout: '#f5f5f5',
//         colorBgMask: 'rgba(0, 0, 0, 0.45)',
//         colorBgSpotlight: 'rgba(0, 0, 0, 0.85)',
//         colorBgTextActive: 'rgba(0, 0, 0, 0.15)',
//         colorBgTextHover: 'rgba(0, 0, 0, 0.06)',
//         colorBorder: '#d9d9d9',
//         colorBorderBg: '#ffffff',
//         colorBorderSecondary: '#f0f0f0',
//         colorError: '#ff4d4f',
//         colorErrorActive: '#d9363e',
//         colorErrorBg: '#fff2f0',
//         colorErrorBgHover: '#fff1f0',
//         colorErrorBorder: '#ffccc7',
//         colorErrorBorderHover: '#ffa39e',
//         colorErrorHover: '#ff7875',
//         colorErrorOutline: 'rgba(255, 38, 5, 0.06)',
//         colorErrorText: '#ff4d4f',
//         colorErrorTextActive: '#d9363e',
//         colorErrorTextHover: '#ff7875',
//         colorFill: 'rgba(0, 0, 0, 0.15)',
//         colorFillAlter: 'rgba(0, 0, 0, 0.02)',
//         colorFillContent: 'rgba(0, 0, 0, 0.06)',
//         colorFillContentHover: 'rgba(0, 0, 0, 0.15)',
//         colorFillQuaternary: 'rgba(0, 0, 0, 0.02)',
//         colorFillSecondary: 'rgba(0, 0, 0, 0.06)',
//         colorFillTertiary: 'rgba(0, 0, 0, 0.04)',
//         colorHighlight: '#ff4d4f',
//         colorIcon: 'rgba(0, 0, 0, 0.45)',
//         colorIconHover: 'rgba(0, 0, 0, 0.88)',
//         colorInfo: '#1677ff',
//         colorInfoActive: '#0958d9',
//         colorInfoBg: '#e6f4ff',
//         colorInfoBgHover: '#bae0ff',
//         colorInfoBorder: '#91caff',
//         colorInfoBorderHover: '#69b1ff',
//         colorInfoHover: '#69b1ff',
//         colorInfoText: '#1677ff',
//         colorInfoTextActive: '#0958d9',
//         colorInfoTextHover: '#4096ff',
//         colorLink: '#1677ff',
//         colorLinkActive: '#0958d9',
//         colorLinkHover: '#69b1ff',
//         colorPrimary: '#1677ff',
//         colorPrimaryActive: '#0958d9',
//         colorPrimaryBg: '#e6f4ff',
//         colorPrimaryBgHover: '#bae0ff',
//         colorPrimaryBorder: '#91caff',
//         colorPrimaryBorderHover: '#69b1ff',
//         colorPrimaryHover: '#4096ff',
//         colorPrimaryText: '#1677ff',
//         colorPrimaryTextActive: '#0958d9',
//         colorPrimaryTextHover: '#4096ff',
//         colorSplit: 'rgba(5, 5, 5, 0.06)',
//         colorSuccess: '#52c41a',
//         colorSuccessActive: '#389e0d',
//         colorSuccessBg: '#f6ffed',
//         colorSuccessBgHover: '#d9f7be',
//         colorSuccessBorder: '#b7eb8f',
//         colorSuccessBorderHover: '#95de64',
//         colorSuccessHover: '#95de64',
//         colorSuccessText: '#52c41a',
//         colorSuccessTextActive: '#389e0d',
//         colorSuccessTextHover: '#73d13d',
//         colorText: 'rgba(0, 0, 0, 0.88)',
//         colorTextBase: '#000',
//         colorTextDescription: 'rgba(0, 0, 0, 0.45)',
//         colorTextDisabled: 'rgba(0, 0, 0, 0.25)',
//         colorTextHeading: 'rgba(0, 0, 0, 0.88)',
//         colorTextLabel: 'rgba(0, 0, 0, 0.65)',
//         colorTextLightSolid: '#fff',
//         colorTextPlaceholder: 'rgba(0, 0, 0, 0.25)',
//         colorTextQuaternary: 'rgba(0, 0, 0, 0.25)',
//         colorTextSecondary: 'rgba(0, 0, 0, 0.65)',
//         colorTextTertiary: 'rgba(0, 0, 0, 0.45)',
//         colorWarning: '#faad14',
//         colorWarningActive: '#d48806',
//         colorWarningBg: '#fffbe6',
//         colorWarningBgHover: '#fff1b8',
//         colorWarningBorder: '#ffe58f',
//         colorWarningBorderHover: '#ffd666',
//         colorWarningHover: '#ffd666',
//         colorWarningOutline: 'rgba(255, 215, 5, 0.1)',
//         colorWarningText: '#faad14',
//         colorWarningTextActive: '#d48806',
//         colorWarningTextHover: '#ffc53d',
//         colorWhite: '#fff',
//         controlHeight: 32,
//         controlHeightLG: 40,
//         controlHeightSM: 24,
//         controlHeightXS: 16,
//         controlInteractiveSize: 16,
//         controlItemBgActive: '#e6f4ff',
//         controlItemBgActiveDisabled: 'rgba(0, 0, 0, 0.15)',
//         controlItemBgActiveHover: '#bae0ff',
//         controlItemBgHover: 'rgba(0, 0, 0, 0.04)',
//         controlOutline: 'rgba(5, 145, 255, 0.1)',
//         controlOutlineWidth: 2,
//         controlPaddingHorizontal: 12,
//         controlPaddingHorizontalSM: 8,
//         controlTmpOutline: 'rgba(0, 0, 0, 0.02)',
//         cyan: '#13C2C2',
//         cyan1: '#e6fffb',
//         cyan2: '#b5f5ec',
//         cyan3: '#87e8de',
//         cyan4: '#5cdbd3',
//         cyan5: '#36cfc9',
//         cyan6: '#13c2c2',
//         cyan7: '#08979c',
//         cyan8: '#006d75',
//         cyan9: '#00474f',
//         cyan10: '#002329',
//         'cyan-1': '#e6fffb',
//         'cyan-2': '#b5f5ec',
//         'cyan-3': '#87e8de',
//         'cyan-4': '#5cdbd3',
//         'cyan-5': '#36cfc9',
//         'cyan-6': '#13c2c2',
//         'cyan-7': '#08979c',
//         'cyan-8': '#006d75',
//         'cyan-9': '#00474f',
//         'cyan-10': '#002329',
//         fontFamily:
//             "-apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial,\n'Noto Sans', sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol',\n'Noto Color Emoji'",
//         fontFamilyCode: "'SFMono-Regular', Consolas, 'Liberation Mono', Menlo, Courier, monospace",
//         fontHeight: 22,
//         fontHeightLG: 24,
//         fontHeightSM: 20,
//         fontSize: 14,
//         fontSizeHeading1: 38,
//         fontSizeHeading2: 30,
//         fontSizeHeading3: 24,
//         fontSizeHeading4: 20,
//         fontSizeHeading5: 16,
//         fontSizeIcon: 12,
//         fontSizeLG: 16,
//         fontSizeSM: 12,
//         fontSizeXL: 20,
//         fontWeightStrong: 600,
//         // geekblue: "#2F54EB",
//         geekblue1: '#f0f5ff',
//         geekblue2: '#d6e4ff',
//         geekblue3: '#adc6ff',
//         geekblue4: '#85a5ff',
//         geekblue5: '#597ef7',
//         geekblue6: '#2f54eb',
//         geekblue7: '#1d39c4',
//         geekblue8: '#10239e',
//         geekblue9: '#061178',
//         geekblue10: '#030852',
//         geekblue: '#f0f5ff',
//         'geekblue-2': '#d6e4ff',
//         'geekblue-3': '#adc6ff',
//         'geekblue-4': '#85a5ff',
//         'geekblue-5': '#597ef7',
//         'geekblue-6': '#2f54eb',
//         'geekblue-7': '#1d39c4',
//         'geekblue-8': '#10239e',
//         'geekblue-9': '#061178',
//         'geekblue-10': '#030852',
//         gold: '#FAAD14',
//         gold1: '#fffbe6',
//         gold2: '#fff1b8',
//         gold3: '#ffe58f',
//         gold4: '#ffd666',
//         gold5: '#ffc53d',
//         gold6: '#faad14',
//         gold7: '#d48806',
//         gold8: '#ad6800',
//         gold9: '#874d00',
//         gold10: '#613400',
//         'gold-1': '#fffbe6',
//         'gold-2': '#fff1b8',
//         'gold-3': '#ffe58f',
//         'gold-4': '#ffd666',
//         'gold-5': '#ffc53d',
//         'gold-6': '#faad14',
//         'gold-7': '#d48806',
//         'gold-8': '#ad6800',
//         'gold-9': '#874d00',
//         'gold-10': '#613400',
//         green: '#52C41A',
//         green1: '#f6ffed',
//         green2: '#d9f7be',
//         green3: '#b7eb8f',
//         green4: '#95de64',
//         green5: '#73d13d',
//         green6: '#52c41a',
//         green7: '#389e0d',
//         green8: '#237804',
//         green9: '#135200',
//         green10: '#092b00',
//         'green-1': '#f6ffed',
//         'green-2': '#d9f7be',
//         'green-3': '#b7eb8f',
//         'green-4': '#95de64',
//         'green-5': '#73d13d',
//         'green-6': '#52c41a',
//         'green-7': '#389e0d',
//         'green-8': '#237804',
//         'green-9': '#135200',
//         'green-10': '#092b00',
//         lime: '#A0D911',
//         lime1: '#fcffe6',
//         lime2: '#f4ffb8',
//         lime3: '#eaff8f',
//         lime4: '#d3f261',
//         lime5: '#bae637',
//         lime6: '#a0d911',
//         lime7: '#7cb305',
//         lime8: '#5b8c00',
//         lime9: '#3f6600',
//         lime10: '#254000',
//         'lime-1': '#fcffe6',
//         'lime-2': '#f4ffb8',
//         'lime-3': '#eaff8f',
//         'lime-4': '#d3f261',
//         'lime-5': '#bae637',
//         'lime-6': '#a0d911',
//         'lime-7': '#7cb305',
//         'lime-8': '#5b8c00',
//         'lime-9': '#3f6600',
//         'lime-10': '#254000',
//         lineHeight: 1.5714285714285714,
//         lineHeightHeading1: 1.2105263157894737,
//         lineHeightHeading2: 1.2666666666666666,
//         lineHeightHeading3: 1.3333333333333333,
//         lineHeightHeading4: 1.4,
//         lineHeightHeading5: 1.5,
//         lineHeightLG: 1.5,
//         lineHeightSM: 1.6666666666666667,
//         lineType: 'solid',
//         lineWidth: 1,
//         lineWidthBold: 2,
//         lineWidthFocus: 4,
//         linkDecoration: 'none',
//         linkFocusDecoration: 'none',
//         linkHoverDecoration: 'none',
//         magenta: '#EB2F96',
//         magenta1: '#fff0f6',
//         magenta2: '#ffd6e7',
//         magenta3: '#ffadd2',
//         magenta4: '#ff85c0',
//         magenta5: '#f759ab',
//         magenta6: '#eb2f96',
//         magenta7: '#c41d7f',
//         magenta8: '#9e1068',
//         magenta9: '#780650',
//         magenta10: '#520339',
//         'magenta-1': '#fff0f6',
//         'magenta-2': '#ffd6e7',
//         'magenta-3': '#ffadd2',
//         'magenta-4': '#ff85c0',
//         'magenta-5': '#f759ab',
//         'magenta-6': '#eb2f96',
//         'magenta-7': '#c41d7f',
//         'magenta-8': '#9e1068',
//         'magenta-9': '#780650',
//         'magenta-10': '#520339',
//         margin: 16,
//         marginLG: 24,
//         marginMD: 20,
//         marginSM: 12,
//         marginXL: 32,
//         marginXS: 8,
//         marginXXL: 48,
//         marginXXS: 4,
//         motion: true,
//         motionBase: 0,
//         motionDurationFast: '0.1s',
//         motionDurationMid: '0.2s',
//         motionDurationSlow: '0.3s',
//         motionEaseInBack: 'cubic-bezier(0.71, -0.46, 0.88, 0.6)',
//         motionEaseInOut: 'cubic-bezier(0.645, 0.045, 0.355, 1)',
//         motionEaseInOutCirc: 'cubic-bezier(0.78, 0.14, 0.15, 0.86)',
//         motionEaseInQuint: 'cubic-bezier(0.755, 0.05, 0.855, 0.06)',
//         motionEaseOut: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
//         motionEaseOutBack: 'cubic-bezier(0.12, 0.4, 0.29, 1.46)',
//         motionEaseOutCirc: 'cubic-bezier(0.08, 0.82, 0.17, 1)',
//         motionEaseOutQuint: 'cubic-bezier(0.23, 1, 0.32, 1)',
//         motionUnit: 0.1,
//         opacityImage: 1,
//         opacityLoading: 0.65,
//         orange: '#FA8C16',
//         orange1: '#fff7e6',
//         orange2: '#ffe7ba',
//         orange3: '#ffd591',
//         orange4: '#ffc069',
//         orange5: '#ffa940',
//         orange6: '#fa8c16',
//         orange7: '#d46b08',
//         orange8: '#ad4e00',
//         orange9: '#873800',
//         orange10: '#612500',
//         'orange-1': '#fff7e6',
//         'orange-2': '#ffe7ba',
//         'orange-3': '#ffd591',
//         'orange-4': '#ffc069',
//         'orange-5': '#ffa940',
//         'orange-6': '#fa8c16',
//         'orange-7': '#d46b08',
//         'orange-8': '#ad4e00',
//         'orange-9': '#873800',
//         'orange-10': '#612500',
//         padding: 16,
//         paddingContentHorizontal: 16,
//         paddingContentHorizontalLG: 24,
//         paddingContentHorizontalSM: 16,
//         paddingContentVertical: 12,
//         paddingContentVerticalLG: 16,
//         paddingContentVerticalSM: 8,
//         paddingLG: 24,
//         paddingMD: 20,
//         paddingSM: 12,
//         paddingXL: 32,
//         paddingXS: 8,
//         paddingXXS: 4,
//         pink: '#eb2f96',
//         pink1: '#fff0f6',
//         pink2: '#ffd6e7',
//         pink3: '#ffadd2',
//         pink4: '#ff85c0',
//         pink5: '#f759ab',
//         pink6: '#eb2f96',
//         pink7: '#c41d7f',
//         pink8: '#9e1068',
//         pink9: '#780650',
//         pink10: '#520339',
//         'pink-1': '#fff0f6',
//         'pink-2': '#ffd6e7',
//         'pink-3': '#ffadd2',
//         'pink-4': '#ff85c0',
//         'pink-5': '#f759ab',
//         'pink-6': '#eb2f96',
//         'pink-7': '#c41d7f',
//         'pink-8': '#9e1068',
//         'pink-9': '#780650',
//         'pink-10': '#520339',
//         purple: '#722ED1',
//         purple1: '#f9f0ff',
//         purple2: '#efdbff',
//         purple3: '#d3adf7',
//         purple4: '#b37feb',
//         purple5: '#9254de',
//         purple6: '#722ed1',
//         purple7: '#531dab',
//         purple8: '#391085',
//         purple9: '#22075e',
//         purple10: '#120338',
//         'purple-1': '#f9f0ff',
//         'purple-2': '#efdbff',
//         'purple-3': '#d3adf7',
//         'purple-4': '#b37feb',
//         'purple-5': '#9254de',
//         'purple-6': '#722ed1',
//         'purple-7': '#531dab',
//         'purple-8': '#391085',
//         'purple-9': '#22075e',
//         'purple-10': '#120338',
//         red: '#F5222D',
//         red1: '#fff1f0',
//         red2: '#ffccc7',
//         red3: '#ffa39e',
//         red4: '#ff7875',
//         red5: '#ff4d4f',
//         red6: '#f5222d',
//         red7: '#cf1322',
//         red8: '#a8071a',
//         red9: '#820014',
//         red10: '#5c0011',
//         'red-1': '#fff1f0',
//         'red-2': '#ffccc7',
//         'red-3': '#ffa39e',
//         'red-4': '#ff7875',
//         'red-5': '#ff4d4f',
//         'red-6': '#f5222d',
//         'red-7': '#cf1322',
//         'red-8': '#a8071a',
//         'red-9': '#820014',
//         'red-10': '#5c0011',
//         screenLG: 992,
//         screenLGMax: 1199,
//         screenLGMin: 992,
//         screenMD: 768,
//         screenMDMax: 991,
//         screenMDMin: 768,
//         screenSM: 576,
//         screenSMMax: 767,
//         screenSMMin: 576,
//         screenXL: 1200,
//         screenXLMax: 1599,
//         screenXLMin: 1200,
//         screenXS: 480,
//         screenXSMax: 575,
//         screenXSMin: 480,
//         screenXXL: 1600,
//         screenXXLMin: 1600,
//         size: 16,
//         sizeLG: 24,
//         sizeMD: 20,
//         sizeMS: 16,
//         sizePopupArrow: 16,
//         sizeSM: 12,
//         sizeStep: 4,
//         sizeUnit: 4,
//         sizeXL: 32,
//         sizeXS: 8,
//         sizeXXL: 48,
//         sizeXXS: 4,
//         volcano: '#FA541C',
//         volcano1: '#fff2e8',
//         volcano2: '#ffd8bf',
//         volcano3: '#ffbb96',
//         volcano4: '#ff9c6e',
//         volcano5: '#ff7a45',
//         volcano6: '#fa541c',
//         volcano7: '#d4380d',
//         volcano8: '#ad2102',
//         volcano9: '#871400',
//         volcano10: '#610b00',
//         'volcano-1': '#fff2e8',
//         'volcano-2': '#ffd8bf',
//         'volcano-3': '#ffbb96',
//         'volcano-4': '#ff9c6e',
//         'volcano-5': '#ff7a45',
//         'volcano-6': '#fa541c',
//         'volcano-7': '#d4380d',
//         'volcano-8': '#ad2102',
//         'volcano-9': '#871400',
//         'volcano-10': '#610b00',
//         wireframe: false,
//         yellow: '#FADB14',
//         yellow1: '#feffe6',
//         yellow2: '#ffffb8',
//         yellow3: '#fffb8f',
//         yellow4: '#fff566',
//         yellow5: '#ffec3d',
//         yellow6: '#fadb14',
//         yellow7: '#d4b106',
//         yellow8: '#ad8b00',
//         yellow9: '#876800',
//         yellow10: '#614700',
//         'yellow-1': '#feffe6',
//         'yellow-2': '#ffffb8',
//         'yellow-3': '#fffb8f',
//         'yellow-4': '#fff566',
//         'yellow-5': '#ffec3d',
//         'yellow-6': '#fadb14',
//         'yellow-7': '#d4b106',
//         'yellow-8': '#ad8b00',
//         'yellow-9': '#876800',
//         'yellow-10': '#614700',
//         zIndexBase: 0,
//         zIndexPopupBase: 1000
//     };

const useAntdTheme = () => {
    const { theme } = useSelector(state => state.common),
        themeColor = theme && themeMap[theme] ? themeMap[theme] : themeMap.light,
        antdThemeCustom = {
            token: {
                colorPrimary: themeColor.customYellow1
            },
            components: {
                // Button: {
                //     colorPrimary: themeColor.customYellow1,
                //     algorithm: true // 启用算法
                // }
            }
        };

    return {
        antdThemeCustom,
        themeColor
    };
};

export default useAntdTheme;
