import React, { memo } from 'react';
import { Input } from 'antd';

const InputCom = props => {
    console.log('child2 render');
    return <Input {...props} />;
};

export default memo(InputCom, (prevProps, nextProps) => {
    if (prevProps.props2.name === nextProps.props2.name) {
        console.log(prevProps.props2.name);
        return true; // 阻止渲染
    }
    return false;
});
