import { Link, useNavigate } from 'react-router-dom';
import React from 'react';
import styles from './style.scss';
import { useDispatch } from 'react-redux';
import { simpleActions } from '@store/common/reducer';

const Header = () => {
    const dispatch = useDispatch(),
        navigate = useNavigate(),
        logout = () => {
            localStorage.setItem('token', '');
            localStorage.setItem('userInfo', null);
            dispatch(simpleActions.setToken(''));
            dispatch(simpleActions.setUserInfo({}));
            navigate('/login');
        };

    return (
        <div className={styles.header}>
            <div className={styles.title}>
                <h1>header</h1>
            </div>
            <div className={styles.menu}>
                <li>
                    <Link to={'/home'}>首页</Link>
                </li>
                <li>
                    <span onClick={logout}>退出登录</span>
                </li>
            </div>
        </div>
    );
};

export default React.memo(Header);
