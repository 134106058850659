import { createSlice, createAction } from '@reduxjs/toolkit';

// 为每个 state 创建一个对应的 setState 方法，
// 如果 state 是 name，那么修改 name 则通过 setName 进行修改
// dispatch(simpleActions.setName('zhangsan'))
const createSetState = initialState => {
        const reducers = {};

        for (const key in initialState) {
            if (initialState.hasOwnProperty(key)) {
                const keyName = 'set' + key.substring(0, 1).toUpperCase() + key.substring(1);

                reducers[keyName] = (state, { payload }) => {
                    state[key] = payload;
                };
            }
        }

        return reducers;
    },
    // 创建 reducer slice
    createReducerSlice = ({
        pageIdentify, // 页面 id
        initialState = {}, // 定义 state 初始值
        reducers = {}, // 可选项，自定义 reducer。其他更复杂的操作，比如对数组的添加/删除，如果不想在外部处理，那就在这里定义
        extraReducers = {} // 可选项，如果 createAsyncThunk 中 return 了需要修改的 state，那么需要传递 extraReducers，统一修改 state；
    }) => {
        const updateState = createAction('updateState'),
            reducerSlice = createSlice({
                name: pageIdentify,
                initialState: initialState,
                reducers: {
                    // 简单 reducer: 一次只能修改一个状态
                    ...createSetState(initialState),
                    // 其他更复杂的操作，比如对数组的添加/删除，如果不想在外部处理，那就在这里定义
                    ...reducers
                },
                extraReducers: builder => {
                    // 复杂 reducer 一次修改多个 state
                    builder.addCase(updateState, (state, { payload }) => {
                        for (const stateKey in payload) {
                            if (payload.hasOwnProperty(stateKey)) {
                                state[stateKey] = payload[stateKey];
                            }
                        }
                    });

                    for (const actionCase in extraReducers) {
                        if (extraReducers.hasOwnProperty(actionCase)) {
                            builder.addCase(actionCase, (state, action) =>
                                extraReducers[actionCase](state, action)
                            );
                        }
                    }
                }
            });

        // 自定义 caseReducer：通过 dispatch(caseReducer.updateState({ name: 'zhangsan', age: 20 })) 可一次修改多个 state
        reducerSlice.caseReducer = {
            updateState
        };

        return reducerSlice;
    };

export default createReducerSlice;
