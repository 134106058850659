import { createAsyncThunk } from '@reduxjs/toolkit';
import { pageIdentify, simpleActions } from './reducer.js';

// createAsyncThunk 可以进行异步操作

// 切换语言： dispatchSetLanguage 是对应 action 的名字
export const asyncSetLanguage = createAsyncThunk(
    `${pageIdentify}/changeLanguage`, // 定义 @reduxjs/toolkit 内部的 actionType
    (payload = {}, { dispatch, getState }) => {
        const { locale } = payload; // payload 接收参数

        if (locale) {
            dispatch(simpleActions.setLocale(locale));
            // i18n.changeLanguage(locale);

            dispatch(asyncSetMenu({}));
        }
    }
);

// 设置菜单
export const asyncSetMenu = createAsyncThunk(
    `${pageIdentify}changeMenu`,
    (payload = {}, { dispatch, getState }) => {
        const { menuList } = payload;

        if (menuList) {
            dispatch(simpleActions.setMenuList(menuList));
        } else {
            // 请求接口
        }
    }
);

// 导出 actions
// dispatch(actions.dispatchSetLanguage({ locale: 'en' }))
const asyncActions = {
    asyncSetLanguage,
    asyncSetMenu
};

export default asyncActions;
