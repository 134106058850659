import createReducerSlice from '@store/createReducerSlice';

export const pageIdentify = 'home';

// 格式化 actions
const initialState = {
        name: 'zhangsan',
        age: 20
    },
    reducerSlice = createReducerSlice({
        pageIdentify,
        initialState
    });

// reducer
export default reducerSlice.reducer;

// 简单 reducer：一次更新一个 state，通过 setXxx 的形式更新 state
// dispatch(simpleActions.setName('zhangsan'))
export const simpleActions = reducerSlice.actions;

// 复杂 reducer：一次更新多个 state
// dispatch(caseReducer.updateState({ name: 'zhangsan', age: 20 }))
export const caseReducer = reducerSlice.caseReducer;
