import React, { useCallback, useEffect, useRef } from 'react';
import { Button } from 'antd';
import { useDispatch } from 'react-redux';
import { simpleActions as commonSimpleActions } from '@store/common/reducer';
import useAntdTheme from '../../common/utils/useAntdTheme';
import styles from './style.scss';
import InputCom from './componetns/input';
import Child from './componetns/child';
import Child2 from './componetns/child2';
import DragResize from './componetns/dragResize';
import DragMove from './componetns/dragMove';
import DragSort from './componetns/dragSort';

const Home = () => {
    const dispatch = useDispatch(),
        { themeColor } = useAntdTheme(),
        ref = useRef(null),
        refChild = useRef(null),
        change = useCallback(() => {
            console.log(refChild.current);
            refChild.current?.changeName && refChild.current.changeName();
        }, []),
        refCon = useRef(null);

    useEffect(() => {
        let refVal = null;

        const resizeObserver = new ResizeObserver(nodes => {
            for (const elem of nodes) {
                console.log(elem.contentRect);
            }
        });

        if (refCon.current) {
            resizeObserver.observe(refCon.current);
            refVal = refCon.current;
        }

        setTimeout(() => {
            if (refCon.current) {
                refCon.current.style.width = '200px';
            }
        }, 5000);

        return () => {
            refVal && resizeObserver.unobserve(refVal);
        };
    }, []);

    return (
        <div>
            <p className={styles.text}>This is App Component.</p>
            <p style={{ color: themeColor.customYellow1 }}>自定义主题色</p>
            <Button type={'primary'}>antd 主题色按钮</Button>
            <br />
            <button onClick={() => dispatch(commonSimpleActions.setTheme('night'))}>切换为深色</button>
            <button onClick={() => dispatch(commonSimpleActions.setTheme('light'))}>切换为浅色</button>
            <button onClick={() => dispatch(commonSimpleActions.setTheme('pink'))}>切换为粉色</button>

            <br />

            <InputCom ref={ref} />

            <br />
            <span onClick={change}>点击调用子组件的方法修改子组件的状态</span>
            <Child ref={refChild} />

            <div
                ref={refCon}
                style={{
                    width: '100px',
                    height: '100px',
                    backgroundColor: 'yellow'
                }}>
                尺寸被监听
            </div>

            <br />

            <Child2 props1={1} props2={{ name: 1 }} />

            <br />

            <DragResize />

            <DragMove />

            <br />

            <DragSort />
        </div>
    );
};

export default React.memo(Home);
