import { configureStore } from '@reduxjs/toolkit';
import commonReducer from './common/reducer';
import homeReducer from '@pages/home/reducer';
// import page2Reducer from '@pages/page2/reducer';

export const store = configureStore({
    // 合并所有的 reducer
    reducer: {
        common: commonReducer,
        home: homeReducer
        // page2: page2Reducer
    },
    // 解决 redux 无法序列化 数据时的报错
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
});
