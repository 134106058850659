import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { simpleActions } from '@store/common/reducer';
import { base64Decode } from './passwordFormat';

const useTokenAuth = () => {
    const navigate = useNavigate(),
        dispatch = useDispatch();

    useEffect(() => {
        const token = localStorage.getItem('token');

        if (!token) {
            dispatch(simpleActions.setToken(''));
            dispatch(simpleActions.setUserInfo({}));
            navigate('/login');
        } else {
            const userInfoStr = localStorage.getItem('userInfo'),
                userInfo = userInfoStr ? JSON.parse(userInfoStr) : {};

            dispatch(simpleActions.setToken(token));
            console.log(userInfo);
            dispatch(
                simpleActions.setUserInfo({
                    username: base64Decode(userInfo.username),
                    password: base64Decode(userInfo.password)
                })
            );
        }
    }, [navigate, dispatch]);
};

export default useTokenAuth;
