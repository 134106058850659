import Base64 from 'base-64';
// base64 加密
export const base64Encode = text => {
    return Base64.encode(text);
};

// base64 解密
export const base64Decode = text => {
    return Base64.decode(text);
};
