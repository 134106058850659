import React, { forwardRef, useImperativeHandle, useState, useCallback } from 'react';

const InputCom = forwardRef(function InputCom(props, ref) {
    const [name, setName] = useState('zhangsan'),
        changeName = useCallback(() => {
            setName(name === 'lisi' ? 'zhangsan' : 'lisi');
        }, [name]);

    useImperativeHandle(ref, () => {
        return {
            changeName
        };
    });

    return (
        <div>
            <span onClick={changeName}>点击切换名字</span>
            <br />
            this is child. {name}
        </div>
    );
});

export default InputCom;
