import React from 'react';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';
import App from './App.js';
import { store } from './store';
// import 'antd/es/theme/style'; // 引入 antd5 的样式
import './css/color.css';
import './css/global.css';

const container = document.getElementById('root'),
    root = createRoot(container);

root.render(
    <React.StrictMode>
        <Provider store={store}>
            <App />
        </Provider>
    </React.StrictMode>
);
