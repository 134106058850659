import React, { memo, useState, useRef, useEffect } from 'react';

const DragCom = () => {
    const ref = useRef(null),
        [isDragging, setIsDragging] = useState(false),
        onMouseDown = e => {
            setIsDragging(true);
        };

    useEffect(() => {
        const mousemove = e => {
                if (!isDragging) {
                    return;
                }

                console.log(e.clientX, e.clientY);

                const originBox = ref.current.getBoundingClientRect();

                if (originBox.x + originBox.width > e.clientX) {
                    // 宽度 缩小
                    if (e.clientX - originBox.x >= 50) {
                        // 最小 50
                        ref.current.style.width = e.clientX - originBox.x + 'px';
                    }
                } else {
                    // 宽度 放大
                    // eslint-disable-next-line no-lonely-if
                    if (e.clientX - originBox.x <= 1000) {
                        // 最大 1000
                        ref.current.style.width = e.clientX - originBox.x + 'px';
                    }
                }

                if (originBox.y + originBox.height > e.clientX) {
                    // 高度 缩小
                    if (e.clientY - originBox.y >= 50) {
                        // 最小 50
                        ref.current.style.height = e.clientY - originBox.y + 'px';
                    }
                } else {
                    // 高度 放大
                    // eslint-disable-next-line no-lonely-if
                    if (e.clientY - originBox.y <= 1000) {
                        // 最大 1000
                        ref.current.style.height = e.clientY - originBox.y + 'px';
                    }
                }
            },
            mouseup = e => {
                setIsDragging(false);
            };

        document.addEventListener('mousemove', mousemove);
        document.addEventListener('mouseup', mouseup);

        return () => {
            document.removeEventListener('mousemove', mousemove);
            document.removeEventListener('mouseup', mouseup);
        };
    }, [isDragging]);

    return (
        <div
            ref={ref}
            onMouseDown={onMouseDown}
            style={{
                width: '100px',
                height: '100px',
                backgroundColor: 'yellow',
                cursor: 'ew-resize' // 鼠标悬停双箭头样式
            }}>
            可拖拽放大，最小 50px, 最大 1000px
        </div>
    );
};

export default memo(DragCom);
