// 将每个 action 对应写一个修改 state 的方法
// 如果 createAsyncThunk 中 return 了需要修改的 state，那么需要对应写一个 extraReducers，统一修改 state；
export const createExtraReducers = (actions = {}) => {
    const extraReducers = {};

    for (const action in actions) {
        if (actions.hasOwnProperty(action)) {
            // action 的异步任务执行完成才修改 state
            extraReducers[actions[action].fulfilled] = (state, { payload }) => {
                for (const key in payload) {
                    if (Object.hasOwnProperty.call(payload, key)) {
                        if (key !== 'callback') {
                            state[key] = payload[key];
                        } else {
                            payload.callback && payload.callback();
                        }
                    }
                }
            };
        }
    }

    return extraReducers;
};
