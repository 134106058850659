import createReducerSlice from '../createReducerSlice';
import { createExtraReducers } from '../createExtraReducers';
import actions from './asyncActions';

export const pageIdentify = 'common';

// 格式化 actions
const initialState = {
        theme: 'night', // 主题
        token: '', // 用户登录鉴权信息
        userInfo: {}, // 用户信息
        orgInfo: {}, // 组织机构信息
        menuListOrg: [], // 扁平化的菜单列表
        menuList: [], // 菜单
        lastVersion: '', // 最新版本
        downloadUrl: {}, // 下载链接等信息
        locale: 'en', // 当前设置的语言
        currentSite: '' // 站点信息
    },
    reducerSlice = createReducerSlice({
        pageIdentify,
        initialState,
        // 如果 createAsyncThunk 中 return 了需要修改的 state，那么需要在这里处理，统一修改 state；
        // 如果 createAsyncThunk 中是直接通过 dispatch(simpleActions.setName('zhangsan')) 修改 state，且没有 return，就不需要传递 extraReducers
        extraReducers: createExtraReducers(actions)
    });

// reducer
export default reducerSlice.reducer;

// 简单 reducer：一次更新一个 state，通过 setXxx 的形式更新 state
// dispatch(simpleActions.setName('zhangsan'))
export const simpleActions = reducerSlice.actions;

// 复杂 reducer：一次更新多个 state
// dispatch(caseReducer.updateState({ name: 'zhangsan', age: 20 }))
export const caseReducer = reducerSlice.caseReducer;
