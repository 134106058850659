import React from 'react';
import Index from '@pages/index.jsx';
import Login from '@pages/login';
import Register from '@pages/register';
import Home from '@pages/home';
import Station from '@pages/station';
import Device from '@pages/device';

const routes = [
    {
        title: '登陆',
        path: '/login',
        component: <Login />
    },
    {
        title: '注册',
        path: '/register',
        component: <Register />
    },
    {
        title: '首页',
        path: '/',
        component: <Index />,
        children: [
            {
                title: '首页',
                path: '/home',
                component: <Home />
            },
            {
                title: '电站',
                path: '/station',
                component: <Station />
            },
            {
                title: '设备',
                path: '/device',
                component: <Device />
            }
        ]
    }
];

export default routes;
