import React, { memo, useRef, useEffect } from 'react';

const DragCom = () => {
    const refDrag = useRef(null),
        initX = useRef(0),
        initY = useRef(0),
        onDragEnd = e => {
            const rect = refDrag.current.getBoundingClientRect();

            refDrag.current.style.left = e.clientX - initX.current + rect.left + 'px';
            refDrag.current.style.top = e.clientY - initY.current + rect.top + 'px';
        },
        onMouseDown = e => {
            initX.current = e.clientX;
            initY.current = e.clientY;
        };

    useEffect(() => {
        const dragOver = e => {
            e.preventDefault();
        };

        document.addEventListener('dragover', dragOver);

        return () => {
            document.removeEventListener('dragover', dragOver);
        };
    }, []);

    return (
        <div
            style={{
                width: '50px',
                height: '50px',
                backgroundColor: 'red',
                position: 'absolute',
                top: 0,
                left: 0
            }}
            draggable={true}
            ref={refDrag}
            onDragEnd={onDragEnd}
            onMouseDown={onMouseDown}>
            拖拽移动
        </div>
    );
};

export default memo(DragCom);
