import React from 'react';
import { ConfigProvider } from 'antd';
import { HashRouter, Navigate, Route, Routes } from 'react-router-dom';
import useAntdTheme from '@utils/useAntdTheme';
import routes from './route/index';

const App = () => {
    const { antdThemeCustom } = useAntdTheme();

    return (
        <HashRouter>
            <ConfigProvider theme={antdThemeCustom}>
                <Routes>
                    {routes.map(item => {
                        if (item.children) {
                            return (
                                <Route key={item.path} path={item.path} element={item.component}>
                                    <Route
                                        path={item.path}
                                        element={<Navigate to={item.children[0].path} />}
                                    />
                                    {item.children.map(childItem => {
                                        return (
                                            <Route
                                                key={childItem.path}
                                                path={childItem.path}
                                                element={childItem.component}
                                            />
                                        );
                                    })}
                                </Route>
                            );
                        }

                        return <Route key={item.path} path={item.path} element={item.component} />;
                    })}
                </Routes>
            </ConfigProvider>
        </HashRouter>
    );
};

export default App;
