import React, { memo, useRef, useEffect, useState } from 'react';
import styles from './dragSort.scss';

const DragCom = () => {
    const [dataList, setDataList] = useState([]),
        ref = useRef(null),
        dragIndex = useRef(0),
        onDragOver = e => {
            e.preventDefault();
        },
        onDragEnd = e => {
            const rect = ref.current.getBoundingClientRect(),
                currentIndex = Math.floor((e.clientY - rect.y) / 40),
                dataListSlice = dataList.slice();

            console.log(currentIndex);

            if (currentIndex === dragIndex.current) {
                return;
            }

            // 交换元素
            dataListSlice[dragIndex.current] = dataList[currentIndex];
            dataListSlice[currentIndex] = dataList[dragIndex.current];
            setDataList(dataListSlice);
        },
        onMouseDown = e => {
            // 计算当前拖拽的第几个元素
            const rect = ref.current.getBoundingClientRect();

            dragIndex.current = Math.floor((e.clientY - rect.y) / 40);
            console.log(dragIndex.current);
        };

    useEffect(() => {
        setDataList([
            {
                id: 1,
                name: '第一行'
            },
            {
                id: 2,
                name: '第二行'
            },
            {
                id: 3,
                name: '第三行'
            },
            {
                id: 4,
                name: '第四行'
            }
        ]);
    }, []);

    return (
        <div ref={ref} onDragOver={onDragOver} className={styles.view}>
            {dataList.map(item => {
                return (
                    <div
                        key={item.id}
                        className={styles.item}
                        draggable={true}
                        onMouseDown={onMouseDown}
                        onDragEnd={onDragEnd}>
                        <span>{item.name}</span>
                    </div>
                );
            })}
        </div>
    );
};

export default memo(DragCom);
