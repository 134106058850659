import React from 'react';
import { Input, Form, Button, Checkbox } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { simpleActions } from '@store/common/reducer';
import { base64Encode } from '@common/utils/passwordFormat';
import styles from './style.scss';

const Login = () => {
    const navigate = useNavigate(),
        dispatch = useDispatch(),
        onFinish = values => {
            console.log('Success:', values);
            if (values.username === '123' && values.password === '123') {
                const token = '123fghsdkkdfghk',
                    userInfo = {
                        username: values.username,
                        password: values.password
                    };

                dispatch(simpleActions.setToken(token));
                dispatch(simpleActions.setUserInfo(userInfo));
                localStorage.setItem('token', token);
                localStorage.setItem(
                    'userInfo',
                    JSON.stringify({
                        username: base64Encode(userInfo.username),
                        password: base64Encode(userInfo.password)
                    })
                );
                navigate('/');
            }
        },
        onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

    return (
        <div className={styles.view}>
            <div className={styles.header}>
                <span>登陆页面</span>
            </div>
            <div className={styles['login-box']}>
                <Form
                    name='basic'
                    labelCol={{
                        span: 8
                    }}
                    wrapperCol={{
                        span: 16
                    }}
                    style={{
                        maxWidth: 600
                    }}
                    initialValues={{
                        remember: true
                    }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                    autoComplete='off'>
                    <Form.Item
                        label='用户名'
                        name='username'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your username!'
                            }
                        ]}>
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label='密码'
                        name='password'
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!'
                            }
                        ]}>
                        <Input.Password />
                    </Form.Item>

                    <Form.Item
                        name='remember'
                        valuePropName='checked'
                        wrapperCol={{
                            offset: 8,
                            span: 16
                        }}>
                        <Checkbox>记住密码</Checkbox>
                    </Form.Item>

                    <Form.Item
                        wrapperCol={{
                            offset: 8,
                            span: 16
                        }}>
                        <Button type='primary' htmlType='submit'>
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    );
};

export default React.memo(Login);
