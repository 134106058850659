export default {
    light: {
        customYellow1: '#f3cb60',
        customYellow2: '#332c0a'
    },
    night: {
        customYellow1: '#390d91',
        customYellow2: '#06046b'
    },
    pink: {
        customYellow1: '#d19dee',
        customYellow2: '#8d5baf'
    }
};
